
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage,
  },
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: true,
    },
    imageClass: {
      type: [String, Array, Object],
      required: false,
      default: null,
    },
    srcPlaceholder: {
      type: String,
      required: false,
      default: null,
    },
    blurLevel: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 7,
    },
  },
  data: () => ({ rate: 1 }),
  computed: {
    deviation() {
      return this.blurLevel * this.rate;
    },
  },
  methods: {
    animate() {
      const start = Date.now() + this.duration;

      const step = () => {
        const remaining = start - Date.now();

        if (remaining < 0) {
          this.rate = 0;
          this.$forceUpdate();
        } else {
          this.rate = remaining / this.duration;
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    },
  },
};
